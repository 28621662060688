import React from "react";
import {Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {AUser} from "../../../lib/types/admin/users";
import UpdateStatusModal from "./UpdateStatusModal";
import UserDetailsModal from "./UserDetailModal";

interface UserListProps {
    data: Array<AUser>
}

const UsersList: React.FC<UserListProps> = ({data}) => {
    return (
        <TableContainer>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Name</Th>
                        <Th>Role</Th>
                        <Th>Active Status</Th>
                        <Th>Approved Status</Th>
                        <Th>Email</Th>
                        <Th>Phone</Th>
                        <Th>User Details</Th>
                        <Th>Update Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data && data.map((user, index) => (
                        <Tr key={index}>
                            <Td>{user.id}</Td>
                            <Td>{user.name}</Td>
                            <Td>{user.role}</Td>
                            <Td>{user.is_active ? 'Active' : 'Inactive'}</Td>
                            <Td>{user.is_approved ? 'Approved' : 'Not Approved'}</Td>
                            <Td>{user.email}</Td>
                            <Td>{user.phone}</Td>
                            <Td><UserDetailsModal user={user}/></Td>
                            <Td>
                                <UpdateStatusModal
                                    user={user}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default UsersList;
