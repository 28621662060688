import {AUser} from "../../lib/types/admin/users";

export const GetAllUsers = async (axios):Promise<Array<AUser>|null> => {
    try {
        const response = await axios.get('/admin/users');
        return response.data?.data;
    }catch (err){
        console.log(err)
        return null
    }
}

export const UpdateUser = async (axios, userId:number, data:any) => {
    try{
        const response = await axios.put(`/admin/user/${userId}`, data);
        return response.data?.data;
    }catch (err){
        console.log(err)
        return null;
    }
}

export const GetUserById = async (axios, userId:number) => {
    try{
        const response = await axios.get(`/admin/user/${userId}`);
        return response.data?.data;
    }catch (err){
        console.log(err)
        return null;
    }
}
