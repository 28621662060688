import {Box, Flex, HStack, Text} from "@chakra-ui/react";
import {Logo} from "../static/Logo";
import {HiOutlineExternalLink} from "react-icons/hi";
import React from "react";
import {useNavigate} from "react-router-dom";

const UserPagesCommonSide: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Box
            display={{base: 'none', md: 'block'}}
            maxW={{base: '20rem', lg: '40rem'}}
            flex="1"
            bg="blue.600"
            color="white"
            px="10"
            py="8"
        >
            <Box>
                <Logo/>
            </Box>
            <Flex
                direction="column"
                align="center"
                justify="center"
                h="full"
                textAlign="center"
                mt="-10"
            >
                <Box>
                    <Text
                        maxW="md"
                        mx="auto"
                        fontWeight="extrabold"
                        fontSize={{base: '4xl', lg: '5xl'}}
                        letterSpacing="tight"
                        lineHeight="normal"
                    >
                        Paavan Creator Platform
                    </Text>
                    <Text mt="5" maxW="sm" mx="auto">
                        Paavan's own content management system.
                    </Text>
                </Box>
                <HStack
                    justify="center"
                    as="a"
                    href="#"
                    minW="2xs"
                    py="3"
                    fontWeight="semibold"
                    px="2"
                    mt="5"
                    border="2px solid white"
                    rounded="lg"
                    _hover={{bg: 'whiteAlpha.200'}}
                    onClick={()=>{
                        navigate('/')
                    }}
                >
                    <HiOutlineExternalLink/>
                    <Text>Home Page</Text>
                </HStack>
            </Flex>
        </Box>
    )
}

export default UserPagesCommonSide;
