import { Box, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import { NavContent } from './NavContent'

export const Navbar = () => {
  return (
    <Box
        position={'fixed'}
        top={0}
        left={0}
        right={0}
        zIndex={1}
    >
      <Box
          as="header"
          bg={mode('white', 'gray.800')}
          w={['100%', '100%', '100%', '100%', '100%']}
          position="fixed"
          zIndex="10"
          h={['auto', 'auto', 'auto', '80px', '80px']}
          maxH={['auto', 'auto', 'auto', '80px', '80px']}
      >
        <Box
          as="nav"
          aria-label="Main navigation"
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <NavContent.Mobile
            display={{
              base: 'flex',
              lg: 'none',
            }}
          />
          <NavContent.Desktop
            display={{
              base: 'none',
              lg: 'flex',
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
