import React, {InputHTMLAttributes} from 'react';
import {FormControl, FormErrorMessage, FormLabel, Select} from "@chakra-ui/react";
import {useField} from "formik";
import {useColorModeValue as mode} from "@chakra-ui/color-mode";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    name: string;
    options: Array<{
        label: string;
        value: string;
    }>;
    props?: any;
    isDisabled?: boolean;
};

const SelectField: React.FC<InputFieldProps> = ({
                                                    label,
                                                    size: _,
                                                    style,
                                                    ...props
                                                }) => {

    const [field, meta] = useField({...props});
    const errorText = meta.error && meta.touched ? meta.error : "";

    return (
        <>
            <FormControl isInvalid={!!meta.error} style={style}>
                <FormLabel
                    _focus={{color: mode('blue.600', 'blue.300')}}
                    fontWeight="semibold"
                    position="absolute"
                    color={mode('gray.600', 'white')}
                    top="-3"
                    insetStart="2"
                    bg={{base: mode('white', 'gray.800'), md: mode('white', 'gray.700')}}
                    zIndex={2}
                    px="2"
                >
                    {label}
                </FormLabel>
                {/*@ts-ignore*/}
                <Select
                    defaultValue={props?.options[0]?.value}
                    id={field.name}
                    size={"lg"}
                    {...field}
                    {...props}
                >
                    {props.options?.map((option, index) => (
                        <option
                            key={index}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </Select>
                {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
            </FormControl>
        </>
    );
};

export default SelectField;
