import {Box, Flex, Spacer, Stack} from '@chakra-ui/react'
import * as React from 'react'
import {SearchField} from './SearchField'
import {UserProfile} from './UserProfile'
import useAuth from "../../../hooks/useAuth";
import SidebarLinks from "../../../lib/SidebarLinks";

export const Sidebar: React.FC = () => {
    return (
        <Flex
            height="100vh"
            width={{base: 'full', sm: 'xs'}}
            direction="column"
            borderRightWidth="1px"
            px={6}
            py={8}
            position={'fixed'}
        >
            <Stack spacing={6}>
                <Box mb={4}>
                    <UserProfile/>
                </Box>
                <SearchField mb={6}/>
                <SidebarLinks/>
            </Stack>
            <Spacer/>
        </Flex>
    )
}
