export const Capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const RemoveUnderscore = (str: string): string => {
    return str.replace(/_/g, ' ');
}

export const ParseAPIKeyString = (str: string): string => {
    //Remove Underscores
    str = RemoveUnderscore(str);

    //Capitalize
    str = Capitalize(str);

    return str;
}
