import {Outlet} from "react-router-dom";
import {useState, useEffect} from "react";
import useAuth from "../hooks/useAuth";
import Loader from "../components/common/Loader";
import {useRefreshToken} from "../hooks/useRefresh";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const {auth} = useAuth();

    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.error(err);
            } finally {
                isMounted && setIsLoading(false);
            }
        }

        (!auth?.access_token) ? verifyRefreshToken() : setIsLoading(false);

        return () => {
            isMounted = false
        };
    }, [])

    return (
        <>
            {
                isLoading
                    ? <Loader/>
                    : <Outlet/>
            }
        </>
    )
}

export default PersistLogin