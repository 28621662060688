import {Box, Button, Flex, LightMode, Stack, Text, useColorModeValue as mode,} from '@chakra-ui/react'
import * as React from 'react'
import {InputField} from "../ui/InputField";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import useAuth from "../../hooks/useAuth";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import useAxios from "../../hooks/useAxios";

export const RegisterUserForm = () => {
    const {setAuth} = useAuth();

    const validationSchema = yup.object({
        email: yup.string().required().email(),
        password: yup.string().min(5).required(),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const navigate = useNavigate();
    const axios = useAxios();

    return (
        <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={{
                email: '',
                password: '',
                confirmPassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (data, {setSubmitting}) => {
                setSubmitting(true);
                try {
                    const response = await axios.post('/auth/register', data);
                    if (response.status === 200) {
                        const {user, token} = response.data;
                        setAuth({user, token})
                        toast.success("Registration Successful")
                    }
                } catch (err: any) {
                    console.log(err);
                    const message = err.response.data.message;
                    toast.error(message);
                    return;
                }
                setSubmitting(false);
                await navigate('/')
            }}
        >
            {({isSubmitting, submitForm}) => (
                <Form>
                    <Stack spacing="8">
                        <InputField
                            name="email"
                            label="Email"
                            type="email"/>
                        <InputField
                            name={'password'}
                            label="Password" type="password"/>
                        <InputField
                            name={'confirmPassword'}
                            label="Confirm Password" type="password"/>
                    </Stack>
                    <Flex
                        // spacing="4"
                        direction={{base: 'column-reverse', md: 'row'}}
                        mt="6"
                        align="center"
                        justify="space-between"
                    >
                        <Text color={mode('gray.600', 'gray.400')} fontSize="sm" fontWeight="semibold">
                            <Box as="a" onClick={() => {
                                navigate('/login')
                            }} color={mode('blue.600', 'blue.300')}>
                                Already have an account? Sign in
                            </Box>
                        </Text>
                        <LightMode>
                            <Button
                                mb={{base: '4', md: '0'}}
                                w={{base: 'full', md: 'auto'}}
                                colorScheme="blue"
                                size="lg"
                                fontSize="md"
                                fontWeight="bold"
                                isLoading={isSubmitting}
                                onClick={() => submitForm()}
                            >
                                Sign Up
                            </Button>
                        </LightMode>
                    </Flex>

                </Form>
            )}
        </Formik>
    )
}