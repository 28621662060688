import {Outlet} from "react-router-dom";
import {Box, SimpleGrid} from "@chakra-ui/react";
import {Navbar} from "./navbar/Navbar";
import {Sidebar} from "./sidebar/Sidebar";

export const Layout = () => {
    return (
        <Box>
            <Navbar/>
            <Box
                display="grid"
                gridTemplateColumns={["1fr", "1fr", "1fr 5fr", "1fr 5fr"]}
            >
                <Box
                    w={["0vw", "0vw", "xs", "xs"]}
                    display={["none", "none", "block", "block"]}
                    mt={["0", "0", "70px", "70px"]}
                >
                    <Sidebar/>
                </Box>
                <SimpleGrid
                    columns={1}
                    mt={["70px", "70px", "70px", "70px"]}
                    p={4}
                    m={4}
                >
                    <Outlet/>
                </SimpleGrid>
            </Box>
        </Box>

    )
}