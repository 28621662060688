import axiosInstance from "../utils/axios";

export const logoutUser = async () => {
    try {
        const response = await axiosInstance.get('/auth/logout');
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const loginUser = async (email: string, password: string) => {
    try {
        const response = await axiosInstance.post('/auth/login', {email, password});
        if (response.status !== 200) {
            throw new Error('Login failed');
        }
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const registerUser = async (email: string, password: string) => {
    try {
        const response = await axiosInstance.post('/auth/register', {email, password});
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}


export const myDetails = async (axios) => {
    try {
        const response = await axios.get('/user/me');
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}


