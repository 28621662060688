import React from "react";
import ReactLoading from "react-loading";
import {Box} from "@chakra-ui/react";


function Loader() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <ReactLoading
                type={"bubbles"}
                color={"#e1970b"}
                height={'10%'}
                width={'10%'}
            />
        </Box>
    );
}

export default Loader;