import {useNavigate} from "react-router-dom"
import {Flex, Text} from "@chakra-ui/react";
import UnApprovedUser from "../components/auth/UnApprovedUser";
import useAuth from "../hooks/useAuth";

const Unauthorized = () => {
    const navigate = useNavigate();
    const {auth} = useAuth();
    const {is_approved} = auth;
    const goBack = () => navigate(-1);

    return (
        <Flex
            direction={"column"}
            align={"center"}
            justify={"center"}
            gap={4}
        >
            <Text
                fontSize={"2xl"}
                fontWeight={"bold"}
            >
                You are not authorized to view this page.
            </Text>

            <Text
                fontSize={"xl"}
                fontWeight={"bold"}
                color={"blue.500"}
                cursor={"pointer"}
                onClick={goBack}
            >
                Go Back
            </Text>
            {!is_approved && <UnApprovedUser/>}
        </Flex>
    )
}

export default Unauthorized;
