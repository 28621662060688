import axios from 'axios';

// let BASE_URL = process.env["REACT_APP_MODE"] == "development" ? process.env["REACT_APP_BASE_URL_DEV"] : process.env["REACT_APP_BASE_URL_PROD"];
const BASE_URL = "https://creatorapi.paavan.app/api"
// const BASE_URL = 'http://localhost:8000/api'

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {},
    withCredentials: true
})

const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {},
    withCredentials: true
})
export {axiosInstance as default, axiosPrivate}
