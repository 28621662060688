import create from 'zustand';

interface NavigationStore {
    currentRoute: string;
    setCurrentRoute: (route: string) => void;
}

// define the store
const useNavigationStore = create<NavigationStore>(set => ({
    currentRoute: '/home',
    setCurrentRoute: (route: string) => set({currentRoute: route}),
}));

// export the store
export default useNavigationStore;
