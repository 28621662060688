import {Avatar, Flex, HStack, Text} from '@chakra-ui/react'
import * as React from 'react'
import useAuth from "../../../hooks/useAuth";

export const UserProfile = () => {
    const {auth} = useAuth();
    const {name, image_url, email, role} = auth;
    return (
        <HStack spacing="4" px="2">
            <Avatar name={name} src={image_url}/>
            <Flex direction="column">
                {name && <Text fontWeight="medium">{name}</Text>}
                {email && <Text fontSize="sm" lineHeight="shorter">
                    {email}
                </Text>}
                {role &&
                <Text
                    fontSize="sm"
                    lineHeight="shorter"
                    color="gray.500"
                >

                    {role}
                </Text>
                }
            </Flex>
        </HStack>
    )
}
