import axiosInstance from "../utils/axios";
import useAuth from "./useAuth";

export const useRefreshToken = () => {
    const {setAuth, auth} = useAuth();

    return async () => {
        const response = await axiosInstance.get('/auth/access-token');

        setAuth({
            ...response.data,
        });

        return response.data.access_token;
    };
}

