import {Box, Button, Flex, LightMode, Stack, Text, useColorModeValue as mode,} from '@chakra-ui/react'
import * as React from 'react'
import {InputField} from "../ui/InputField";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import useAuth from "../../hooks/useAuth";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import {loginUser} from "../../services/auth";

export const SigninForm = () => {
    const {auth, setAuth} = useAuth();

    const validationSchema = yup.object({
        email: yup.string().required().email(),
        password: yup.string().min(5).required()
    });

    const navigate = useNavigate();
    const axios = useAxios();

    return (
        <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={validationSchema}
            onSubmit={async (data, {setSubmitting}) => {
                setSubmitting(true);
                try {
                    const user = await loginUser(data.email, data.password);

                    if (user.status !== 200) {
                        throw new Error(user.message)
                    }
                    setAuth(user.data)
                    toast.success("Login Successful")
                    await navigate('/')
                } catch (err: any) {
                    console.log(err);
                    const message = err.response.data.message;
                    toast.error(message);
                    return;
                }
                setSubmitting(false);
            }}
        >
            {({isSubmitting, submitForm}) => (
                <Form>
                    <Stack spacing="8">
                        <InputField
                            name="email"
                            label="Email"
                            type="email"/>
                        <Box>
                            <InputField
                                name={'password'}
                                label="Password" type="password"/>
                            <Box
                                display="inline-block"
                                as="a"
                                href="#"
                                color={mode('blue.600', 'blue.300')}
                                fontWeight="semibold"
                                fontSize="sm"
                                mt="3"
                            >
                                Forgot password?
                            </Box>
                        </Box>
                    </Stack>
                    <Flex
                        // spacing="4"
                        direction={{base: 'column-reverse', md: 'row'}}
                        mt="6"
                        align="center"
                        justify="space-between"
                    >
                        <Text color={mode('gray.600', 'gray.400')} fontSize="sm" fontWeight="semibold">
                            New user?{' '}
                            <Box as="a" onClick={() => {
                                navigate('/register-user')
                            }} color={mode('blue.600', 'blue.300')}>
                                Create account
                            </Box>
                        </Text>
                        <LightMode>
                            <Button
                                mb={{base: '4', md: '0'}}
                                w={{base: 'full', md: 'auto'}}
                                colorScheme="blue"
                                size="lg"
                                fontSize="md"
                                fontWeight="bold"
                                isLoading={isSubmitting}
                                onClick={() => submitForm()}
                            >
                                Sign in
                            </Button>
                        </LightMode>
                    </Flex>

                </Form>
            )}
        </Formik>
    )
}
