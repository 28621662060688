import React from 'react';
import {Router} from "./lib/router";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ChakraProvider} from '@chakra-ui/react'
import {extendTheme, ThemeConfig} from '@chakra-ui/react'

function App() {
    return (
        <>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ChakraProvider theme={theme}>
                <Router/>
            </ChakraProvider>
        </>

    )
}


const theme: ThemeConfig = extendTheme({
    config: {
        initialColorMode: "dark",
        useSystemColorMode: false,
    },
    styles: {
        global: (props) => {
            return ({
                '*': {
                    boxSizing: 'border-box'
                },
                body: {
                    overflowX: 'hidden',
                    color: props.colorMode === 'light' ? 'black' : 'white',
                    bg: props.colorMode === 'light' ? 'gray.100' : 'gray.800',
                }
            })
        }
    },
    colors: {
        default: {
            light: '#fafafa',
            dark: 'gray.800'
        },
        primary: {},
        secondary: {},
    }//
})

export default App;
