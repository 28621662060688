import {VideosUploadedByUserResponseI} from "../lib/types/video";

export const GetVideosUploadedByUser = async (axios):Promise<VideosUploadedByUserResponseI|null> => {
    try {
        const res = await axios.get(`/upload-video/get-videos`)
        return res.data
    } catch (err) {
        console.log(err)
        return null
    }
}
