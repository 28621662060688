import React from "react";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import {Button, Flex, LightMode, Stack} from "@chakra-ui/react";
import {InputField} from "../ui/InputField";
import SelectField from "../ui/SelectField";
import UploadImage from "../common/UploadImage";
import {UpdateUserRegistrationStep1, UpdateUserRegistrationStep2} from "../../services/user_registration";


interface UserDetailsRegistrationFormStep2Props {
    id: string;
    currentStep: number;
    handleNext: () => void;
    handleBack: () => void;

    prevUserDetails: any;
}

const UserDetailsRegistrationFormStep2: React.FC<UserDetailsRegistrationFormStep2Props> = ({
                                                                                               id,
                                                                                               currentStep,
                                                                                               handleNext,
                                                                                               handleBack,
                                                                                               prevUserDetails

                                                                                           }) => {
    const validationSchema = yup.object({
        contract_file_url: yup.string().required(),
        bank_account_number: yup.string().required(),
        bank_name: yup.string().required(),
        bank_branch: yup.string().required(),
        bank_ifsc: yup.string().required(),
        bank_account_type: yup.string().required(),
        bank_account_holder_name: yup.string().required(),
    });

    const navigate = useNavigate();
    const axios = useAxios();
    return (
        <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={{
                contract_file_url: prevUserDetails?.contract_file_url || '',
                bank_account_number: prevUserDetails?.bank_account_number || '',
                bank_name: prevUserDetails?.bank_name || '',
                bank_branch: prevUserDetails?.bank_branch || '',
                bank_ifsc: prevUserDetails?.bank_ifsc || '',
                bank_account_type: prevUserDetails?.bank_account_type || '',
                bank_account_holder_name: prevUserDetails?.bank_account_holder_name || '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (data, {setSubmitting}) => {
                setSubmitting(true);
                try {
                    const response = await UpdateUserRegistrationStep2(data, axios);

                    if (response.status === 200) {
                        // navigate('/dashboard');
                    } else {
                        toast.error('Something went wrong');
                    }
                } catch (err: any) {
                    console.log(err);
                    const message = err.response.data.message;
                    toast.error(message);
                    return;
                }
                setSubmitting(false);
            }}
        >
            {({isSubmitting, submitForm, setValues, values, errors}) => (
                <Form>
                    <Stack spacing="8">
                        <InputField
                            name={'contract_file_url'}
                            label="ID Card File URL"
                            type="text"
                            isDisabled={true}
                            isDisplayed={false}
                        />

                        <UploadImage
                            setImageUrl={(url: string) => {
                                setValues({...values, contract_file_url: url})
                            }}
                            imageURL={values['contract_file_url']}
                            userID={id}
                            folderName={'contracts'}
                        />

                        <InputField
                            name="bank_account_number"
                            label="Bank Account Number"
                            type="text"
                        />

                        <InputField
                            name="bank_name"
                            label="Bank Name"
                            type="text"
                        />

                        <InputField
                            name="bank_branch"
                            label="Bank Branch"
                            type="text"
                        />

                        <InputField
                            name="bank_ifsc"
                            label="Bank IFSC"
                            type="text"
                        />

                        <InputField
                            name="bank_account_type"
                            label="Bank Account Type"
                            type="text"
                        />

                        <InputField
                            name="bank_account_holder_name"
                            label="Bank Account Holder Name"
                            type="text"
                        />
                    </Stack>
                    <Flex
                        direction={{base: 'column-reverse', md: 'row'}}
                        mt="6"
                        align="center"
                        justify="space-between"
                    >
                        <LightMode>
                            <Button
                                mb={{base: '4', md: '0'}}
                                w={{base: 'full', md: 'auto'}}
                                colorScheme="blue"
                                size="lg"
                                fontSize="md"
                                variant={'outline'}
                                fontWeight="bold"
                                isLoading={isSubmitting}
                                onClick={() => handleBack()}
                                isDisabled={currentStep === 0}
                            >
                                Go Back
                            </Button>

                            <Button
                                mb={{base: '4', md: '0'}}
                                w={{base: 'full', md: 'auto'}}
                                colorScheme="blue"
                                size="lg"
                                fontSize="md"
                                fontWeight="bold"
                                isLoading={isSubmitting}
                                onClick={() => submitForm()}
                                isDisabled={
                                    Object.keys(errors).length > 0 ||
                                    isSubmitting
                                }
                            >
                                Save
                            </Button>
                        </LightMode>
                    </Flex>

                </Form>
            )}
        </Formik>
    );
};

export default UserDetailsRegistrationFormStep2;