import React from "react";
import {Box, Text} from "@chakra-ui/react";
import {GetAllUsers} from "../../services/admin/users";
import useAxios from "../../hooks/useAxios";
import {AUser} from "../../lib/types/admin/users";
import UsersList from "../../components/admin/users/UserList";
import Loader from "../../components/common/Loader";

interface Props {

}

const Users:React.FC<Props> = () => {
    const [data, setData] = React.useState<Array<AUser>|null>(null);
    const axios = useAxios();
    React.useEffect(() => {
            const fetchData = async () => {
                const response = await GetAllUsers(axios);
                setData(response);
            }
            fetchData();
    }, [])

    if (!data) {
        return <Loader/>
    }

    return (
        <Box>
            <Text
                fontSize={'2xl'}
                fontWeight={'bold'}
                textAlign={'left'}
                mt={4}
                mb={4}
            >
                Users Signed Up
            </Text>
            {data && <UsersList
                data={data}
            />}
        </Box>
    )
}

export default Users
