import {Image, useColorModeValue} from '@chakra-ui/react'
import * as React from 'react'

export const Logo = () => {
    return (
        <Image
            color={useColorModeValue('blue.500', 'blue.300')}
            aria-hidden
            h="40px"
            flexShrink={0}
            src={require("../../assets/logo.png")}
        />
    )
}
