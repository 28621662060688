import * as React from 'react';
import {Logo} from "../components/static/Logo";
import {Box, Flex, Heading, Text, useColorModeValue as mode} from '@chakra-ui/react'
import {SigninForm} from "../components/auth/SignInForm";
import UserPagesCommonSide from "../components/common/UserPagesCommonSide";


export const Login = () => {
    return (
        <Flex minH="100vh" direction={{ base: 'column', md: 'row' }}>
            <UserPagesCommonSide/>
            <Box
                flex="1"
                px={{ base: '6', md: '10', lg: '16', xl: '28' }}
                py={{ base: '10', md: '64' }}
                bg={{ md: mode('gray.50', 'gray.800') }}
            >
                <Box maxW="xl">
                    <Box>
                        <Box display={{ md: 'none' }} mb="16">
                            <Logo/>
                        </Box>
                        <Heading
                            color={mode('blue.600', 'blue.400')}
                            as="h1"
                            size="2xl"
                            fontWeight="extrabold"
                            letterSpacing="tight"
                        >
                            Welcome back
                        </Heading>
                        <Text
                            mt="3"
                            fontSize={{ base: 'xl', md: '3xl' }}
                            fontWeight="bold"
                            color={mode('gray.500', 'inherit')}
                        >
                            Sign in to continue
                        </Text>
                    </Box>

                    <Box
                        minW={{ md: '420px' }}
                        mt="10"
                        rounded="xl"
                        bg={{ md: mode('white', 'gray.700') }}
                        shadow={{ md: 'lg' }}
                        px={{ md: '10' }}
                        pt={{ base: '8', md: '12' }}
                        pb="8"
                    >
                        <SigninForm />
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
}
