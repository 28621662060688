import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import {Logo} from "../components/static/Logo";
import {useColorModeValue as mode} from "@chakra-ui/color-mode";
import UserPagesCommonSide from "../components/common/UserPagesCommonSide";
import UserDetailsRegistrationC from "../components/user_details";

const UserRegistrationDetailsPage: React.FC = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    if (!id || id == "undefined") {
        //Redirect to login page
        toast.error("Something went wrong. Please try again")
        navigate('/login')

        return (
            <Text>Redirecting...</Text>
        )
    }


    return (
        <>
            <Flex minH="80vh" direction={{base: 'column', md: 'row'}}>
                <UserPagesCommonSide/>
                <Box
                    flex="1"
                    px={{base: '6', md: '10', lg: '16', xl: '28'}}
                    py={{base: '10', md: '24'}}
                    bg={{md: mode('gray.50', 'gray.800')}}
                >
                    <Box maxW="xl">
                        <Box>
                            <Box display={{md: 'none'}} mb="16">
                                <Logo  />
                            </Box>
                            <Heading
                                color={mode('blue.600', 'blue.400')}
                                as="h1"
                                size="2xl"
                                fontWeight="extrabold"
                                letterSpacing="tight"
                            >
                                Welcome To Paavan
                            </Heading>
                            <Text
                                mt="3"
                                fontSize={{base: 'xl', md: '3xl'}}
                                fontWeight="bold"
                                color={mode('gray.500', 'inherit')}
                            >
                                Fill In Your Details
                            </Text>
                        </Box>

                        <Box
                            minW={{md: '420px'}}
                            mt="10"
                            rounded="xl"
                            bg={{md: mode('white', 'gray.700')}}
                            shadow={{md: 'lg'}}
                            px={{md: '10'}}
                            pt={{base: '8', md: '12'}}
                            pb="8"
                        >
                            <UserDetailsRegistrationC id={id}/>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </>
    )
}

export default UserRegistrationDetailsPage;
