import React from "react";
import {AUser} from "../../../lib/types/admin/users";
import {
    Box,
    Button, CircularProgress,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Radio, RadioGroup, Stack, Text,
    useDisclosure
} from "@chakra-ui/react";
import {GetUserById, UpdateUser} from "../../../services/admin/users";
import useAxios from "../../../hooks/useAxios";
import {toast} from "react-toastify";
import Loader from "../../common/Loader";
import {ParseAPIKeyString} from "../../../utils/strings";

interface Props {
    user: AUser
}

const UserDetailsModal:React.FC<Props> = ({user}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState<any|null>(null);
    const axios = useAxios();

    React.useEffect(() => {
        setIsLoading(true);
        async function fetchData() {
            const response = await GetUserById(axios, user.id);
            if(response){
                setData(response);
            }
            setIsLoading(false);
        }
        fetchData();
    }, [isOpen])

    return (
        <>
            <Button
                colorScheme='blue'
                onClick={onOpen}
            >View</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Update User Status</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {isLoading && <Loader/>}
                        {!isLoading &&
                            <Stack spacing={4}>
                                {
                                    data && Object.keys(data).map((key, index) => (
                                        <Box key={index}>
                                            <Box fontWeight='bold'>
                                                <Text
                                                    size={'xl'}
                                                    color={'gray.500'}

                                                >
                                                    {ParseAPIKeyString(key)}
                                                </Text>
                                            </Box>
                                            <Box>
                                                {
                                                //     If is boolean then show yes or no
                                                    typeof data[key] === 'boolean' ?
                                                        data[key] ? 'Yes' : 'No'
                                                        :
                                                        data[key]
                                                }
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Stack>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UserDetailsModal;
