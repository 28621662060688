import {Box, Button, Flex, HStack, useColorModeValue as mode, useDisclosure, VisuallyHidden,} from '@chakra-ui/react'
import * as React from 'react'
import {NavLink} from './NavLink'
import {NavMenu} from './NavMenu'
import {Submenu} from './Submenu'
import {ToggleButton} from './ToggleButton'
import {links, protectedLinks} from './_data'
import {Logo} from "../../static/Logo";
import useAuth from "../../../hooks/useAuth";
import SidebarLinks from "../../../lib/SidebarLinks";
import {FaHome, FaUser} from "react-icons/fa";
import {logoutUser} from "../../../services/auth";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {NavLinkMobile} from "../sidebar/NavLink";

const MobileNavContext = (props) => {
    const {isOpen, onToggle} = useDisclosure()
    const {setAuth}= useAuth()
    const navigate = useNavigate();
    return (
        <>
            <Flex
                h={["auto", "auto", "80px", "80px"]}
                align="center"
                justify="space-between"
                className="nav-content__mobile"
                {...props}
            >
                <Box flexBasis="6rem">
                    <ToggleButton isOpen={isOpen} onClick={onToggle}/>
                </Box>
                <Box
                    as="a"
                    rel="home"
                >
                    <Logo />
                </Box>

            </Flex>
            <NavMenu animate={isOpen ? 'open' : 'closed'}>
                <NavLinkMobile label={"Home"} href={'/home'} icon={FaHome} />
                <NavLinkMobile label={"Profile"} href={'/profile'} icon={FaUser}/>
                <Button
                    w={'full'}
                    variant="outline"
                    colorScheme="blue"
                    mt={4}
                    onClick={
                        async () => {
                            await logoutUser()
                            setAuth(null)
                            navigate('/login')
                            toast.success('Logged out successfully')
                        }
                    }
                >Logout</Button>
            </NavMenu>
        </>
    )
}

const DesktopNavContent = (props) => {
    const {auth} = useAuth();
    return (
        <Flex h={["auto", "auto", "80px", "80px"]} className="nav-content__desktop" align="center"
              justify="space-between" {...props}>
            <Box as="a" href="/home" rel="home">
                <VisuallyHidden>Envelope</VisuallyHidden>
                <Logo/>
            </Box>
            {/*<HStack as="ul" id="nav__primary-menu" aria-label="Main Menu" listStyleType="none">*/}
            {/*    {GetLinksData().map((link, idx) => (*/}
            {/*        <Box as="li" key={idx} id={`nav__menuitem-${idx}`}>*/}
            {/*            {link.children ? (*/}
            {/*                <Submenu.Desktop link={link}/>*/}
            {/*            ) : (*/}
            {/*                <NavLink.Desktop href={link.href}>{link.label}</NavLink.Desktop>*/}
            {/*            )}*/}
            {/*        </Box>*/}
            {/*    ))}*/}
            {/*</HStack>*/}
            <HStack spacing="8" minW="240px" justify="space-between">
                {!auth && <Box as="a" href="/login" color={mode('blue.600', 'blue.300')} fontWeight="bold">
                    Sign In
                </Box>}
            </HStack>
        </Flex>
    )
}

const GetLinksData = () => {
    const {auth} = useAuth();
    if (auth) {
        return protectedLinks;
    }

    return links;
}

export const NavContent = {
    Mobile: MobileNavContext,
    Desktop: DesktopNavContent,
}
