import React from "react";
import {Box} from "@chakra-ui/react";
import UserDetailsRegistrationFormStep1 from "./UserRegistrationFromStep1";
import UserDetailsRegistrationFormStep2 from "./UserRegistrationFromStep2";
import {myDetails} from "../../services/auth";
import useAxios from "../../hooks/useAxios";

interface Props {
    id: string
}

const UserDetailsRegistrationC: React.FC<Props> = ({id}) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const [prevUserDetails, setPrevUserDetails] = React.useState<any>(null);
    const axios = useAxios();
    React.useEffect(() => {
        async function fn() {
            const data = await myDetails(axios);
            if (data) {
                console.log(data)
                setPrevUserDetails(data?.data);
            }
        }

        fn()
    }, [])


    const handleNext = async () => {
        if (currentStep === 0) {
            setCurrentStep(1)
        }
    }

    const handleBack = () => {
        if (currentStep === 1) {
            setCurrentStep(0)
        }
    }

    return (
        <>
            <Box
                display={
                    currentStep === 0 ? 'block' : 'none'
                }
            >
                <UserDetailsRegistrationFormStep1
                    id={id}
                    currentStep={currentStep}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    prevUserDetails={prevUserDetails}
                />
            </Box>
            <Box
                display={
                    currentStep === 1 ? 'block' : 'none'
                }
            >
                <UserDetailsRegistrationFormStep2
                    id={id}
                    currentStep={currentStep}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    prevUserDetails={prevUserDetails}
                />
            </Box>
        </>
    )
}

export default UserDetailsRegistrationC;