import React from "react";
import {Box, Heading, Stack} from "@chakra-ui/react";
import useAuth from "../hooks/useAuth";
import UserDetailsRegistrationC from "../components/user_details";
import {useColorModeValue as mode} from "@chakra-ui/color-mode";

const Profile: React.FC = () => {
    const {auth} = useAuth();
    const {id} = auth;

    return (
        <Box

        >
            <Stack
                spacing={4}
                w={'full'}
                maxW={'md'}
                p={6}>
                <Heading lineHeight={1.1} fontSize={{base: '2xl', sm: '3xl'}} mb={4}>
                    Update Profile
                </Heading>
                <Box
                    minW={{md: '420px'}}
                    rounded="xl"
                    bg={{md: mode('white', 'gray.700')}}
                    shadow={{md: 'lg'}}
                    px={{md: '10'}}
                    pt={{base: '8', md: '12'}}
                    pb="8"
                >
                    <UserDetailsRegistrationC id={id}/>
                </Box>
            </Stack>
        </Box>
    )
}

export default Profile;