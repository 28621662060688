import React, {createContext, useState} from "react";
import {AuthContentInterface} from "../lib/types/auth";

const initial = {
    auth: {
        id: '',
        name: '',
        email: '',
        image_url: '',
        access_token: '',
        is_approved: false,
        role: '',
    },
}

const AuthContext = createContext<AuthContentInterface>(initial);

export const AuthProvider: React.FC<{
    children: React.ReactNode
}> = ({children}) => {
    const [auth, setAuth] = useState<AuthContentInterface>(initial);
    return (
        // @ts-ignore
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;

