import {Navigate, Outlet, useLocation} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import React from "react";


const RequireAuth: React.FC = () => {
    const {auth} = useAuth();
    const location = useLocation();
    const {id, is_approved, role} = auth;
    //Dont allow user to access any /admin routes if they are not an admin
    if (location.pathname.startsWith('/admin') && role !== 'admin') {
        return <Navigate to="/unauthorized" state={{from: location}} replace/>
    }
    return (
        id
            ? (is_approved)
                ? <Outlet/> : <Navigate to="/unauthorized" state={{from: location}} replace/> :
            <Navigate to="/login" state={{from: location}} replace/>
    );
}

export default RequireAuth;
