
export class Uploader {
    private api:any;

    private title:string;
    private chunkSize: number;
    private threadsQuantity: number;
    private file: any;
    private fileName: any;

    private aborted: boolean;
    private uploadedSize: number;
    private progressCache: any;
    private activeConnections: any;
    private parts: any[];
    private uploadedParts: any[];
    private fileId: any;
    private fileKey: any;
    private onProgressFn: () => void;
    private onErrorFn: () => void;
    private onFinishedFn: (url) => void;

    constructor(options) {
        this.api = options.api
        this.title = options.title
        this.chunkSize = options.chunkSize || 1024 * 1024 * 5
        this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15)
        this.file = options.file
        this.fileName = options.fileName
        this.aborted = false
        this.uploadedSize = 0
        this.progressCache = {}
        this.activeConnections = {}
        this.parts = []
        this.uploadedParts = []
        this.fileId = null
        this.fileKey = null
        this.onProgressFn = () => {
            console.log("progress")
        }
        this.onErrorFn = () => {
            console.log("error")
        }
        this.onFinishedFn = (url) => {
            console.log("finished", url)
        }

    }

    // starting the multipart upload request
    start() {
        this.initialize()
    }

    async initialize() {
        try {
            // adding the the file extension (if present) to fileName
            let fileName = this.fileName
            const ext = this.file.name.split(".").pop()
            if (ext) {
                fileName += `.${ext}`
            }

            // initializing the multipart request
            const videoInitializationUploadInput = {
                name: fileName,
            }
            const initializeReponse = await this.api.request({
                url: "/upload-video/initialize",
                method: "POST",
                data: videoInitializationUploadInput,
            })

            const AWSFileDataOutput = initializeReponse.data

            this.fileId = AWSFileDataOutput.fileId
            this.fileKey = AWSFileDataOutput.fileKey

            // retrieving the pre-signed URLs
            const numberOfparts = Math.ceil(this.file.size / this.chunkSize)

            const AWSMultipartFileDataInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: numberOfparts,
            }

            const urlsResponse = await this.api.request({
                url: "/upload-video/getMultipartPreSignedUrls",
                method: "POST",
                data: AWSMultipartFileDataInput,
            })

            const newParts = urlsResponse.data.parts
            this.parts.push(...newParts)

            this.sendNext()
        } catch (error) {
            await this.complete(error)
        }
    }

    sendNext() {
        const activeConnections = Object.keys(this.activeConnections).length

        if (activeConnections >= this.threadsQuantity) {
            return
        }

        if (!this.parts.length) {
            if (!activeConnections) {
                //@ts-ignore
                this.complete()
            }

            return
        }

        const part = this.parts.pop()

        if (this.file && part) {
            const sentSize = (part.PartNumber - 1) * this.chunkSize
            const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

            const sendChunkStarted = () => {
                this.sendNext()
            }

            this.sendChunk(chunk, part, sendChunkStarted)
                .then(() => {
                    this.sendNext()
                })
                .catch((error) => {
                    this.parts.push(part)

                    this.complete(error)
                })
        }
    }

    // terminating the multipart upload request on success or failure
    async complete(error) {
        if (error && !this.aborted) {
            //@ts-ignore
            this.onErrorFn(error)
            return
        }

        if (error) {
            //@ts-ignore
            this.onErrorFn(error)
            return
        }

        try {
            await this.sendCompleteRequest()
        } catch (error) {
            //@ts-ignore
            this.onErrorFn(error)
        }
    }

    // finalizing the multipart upload request on success by calling
    // the finalization API
    async sendCompleteRequest() {
        if (this.fileId && this.fileKey) {
            const videoFinalizationMultiPartInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: this.uploadedParts,
                title: this.title,
            }

            const response = await this.api.request({
                url: "/upload-video/finalize",
                method: "POST",
                data: videoFinalizationMultiPartInput,
            })

            if (response.status === 200) {
                this.onFinishedFn(
                    response.data,
                )
            }else{
                this.onError(response)
            }
        }
    }

    sendChunk(chunk, part, sendChunkStarted) {
        return new Promise<void>((resolve, reject) => {
            this.upload(chunk, part, sendChunkStarted)
                .then((status) => {
                    if (status !== 200) {
                        reject(new Error("Failed chunk upload"))
                        return
                    }

                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    // calculating the current progress of the multipart upload request
    handleProgress(part, event) {
        if (this.file) {
            if (event.type === "progress" || event.type === "error" || event.type === "abort") {
                this.progressCache[part] = event.loaded
            }

            if (event.type === "uploaded") {
                this.uploadedSize += this.progressCache[part] || 0
                delete this.progressCache[part]
            }

            const inProgress = Object.keys(this.progressCache)
                .map(Number)
                .reduce((memo, id) => (memo += this.progressCache[id]), 0)

            const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

            const total = this.file.size

            const percentage = Math.round((sent / total) * 100)

            //@ts-ignore
            this.onProgressFn({
                sent: sent,
                total: total,
                percentage: percentage,
            })
        }
    }

    // uploading a part through its pre-signed URL
    upload(file, part, sendChunkStarted) {
        // uploading each part with its pre-signed URL
        return new Promise((resolve, reject) => {
            try{
                if (this.fileId && this.fileKey) {
                    // - 1 because PartNumber is an index starting from 1 and not 0
                    const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest())

                    sendChunkStarted()

                    const progressListener = this.handleProgress.bind(this, part.PartNumber - 1)

                    xhr.upload.addEventListener("progress", progressListener)

                    xhr.addEventListener("error", progressListener)
                    xhr.addEventListener("abort", progressListener)
                    xhr.addEventListener("loadend", progressListener)

                    xhr.open("PUT", part.signedUrl)

                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4 && xhr.status === 200) {
                            console.log(xhr)
                            // retrieving the ETag parameter from the HTTP headers
                            const ETag = xhr.getResponseHeader("ETag")

                            //Refused to set unsafe header "ETag"
                            //Fix
                            //https://stackoverflow.com/questions/20035101/failed-to-execute-setrequestheader-on-xmlhttprequest-header-name-is-not-a-saf

                            if (ETag) {
                                const uploadedPart = {
                                    PartNumber: part.PartNumber,
                                    // removing the " enclosing carachters from
                                    // the raw ETag
                                    ETag: ETag.replaceAll('"', ""),
                                }

                                this.uploadedParts.push(uploadedPart)

                                resolve(xhr.status)
                                delete this.activeConnections[part.PartNumber - 1]
                            }
                        }
                    }

                    xhr.onerror = (error) => {
                        reject(error)
                        delete this.activeConnections[part.PartNumber - 1]
                    }

                    xhr.onabort = () => {
                        reject(new Error("Upload canceled by user"))
                        delete this.activeConnections[part.PartNumber - 1]
                    }
                    console.log("file", file)
                    xhr.send(file)

                }
            }catch (err){
                console.log("err", err)
            }
        })
    }

    onProgress(onProgress) {
        this.onProgressFn = onProgress
        return this
    }

    onError(onError) {
        this.onErrorFn = onError
        return this
    }

    onFinished(onFinished) {
        this.onFinishedFn = onFinished
        return this
    }

    abort() {
        Object.keys(this.activeConnections)
            .map(Number)
            .forEach((id) => {
                this.activeConnections[id].abort()
            })

        this.aborted = true
    }
}
