import {
    FormControl, FormErrorMessage,
    FormLabel,
    Input,
    InputProps,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import {useField} from "formik";

interface InputFieldProps extends InputProps {
    label: string
    isDisplayed?: boolean
}

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
    const {label, isDisplayed, ...rest} = props
    // @ts-ignore
    const [field, meta] = useField({...rest});
    const errorText = meta.error && meta.touched ? meta.error : "";

    if (isDisplayed!==undefined && !isDisplayed) {
        return null
    }

    return (
        <FormControl position="relative" isInvalid={!!meta.error}>
            <FormLabel
                _focus={{color: mode('blue.600', 'blue.300')}}
                fontWeight="semibold"
                position="absolute"
                color={mode('gray.600', 'white')}
                top="-3"
                insetStart="2"
                bg={{base: mode('white', 'gray.800'), md: mode('white', 'gray.700')}}
                zIndex={2}
                px="2"
            >
                {label}
            </FormLabel>
            <Input ref={ref} size="lg" fontSize="md" id={field.name}
                   {...field} {...rest} />
            {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
        </FormControl>
    )
})

InputField.displayName = 'InputField'
