export const UpdateUserRegistrationStep1 = async (data, axios) => {
    try {
        const res = await axios.post('/user/register-user-step-1', data)
        return res.data
    } catch (err) {
        console.log(err)
        return null
    }
}

export const UpdateUserRegistrationStep2 = async (data, axios) => {
    try {
        const res = await axios.post('/user/register-user-step-2', data)
        return res.data
    } catch (err) {
        console.log(err)
        return null
    }
}