import React from "react";
import {Button, Flex, Text} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const UnApprovedUserC: React.FC = () => {
    const navigate = useNavigate();
    const {auth} = useAuth();
    return (
        <Flex
            direction="column"
            alignItems="center"
            height="50vh"
            gap={4}
        >
            <Text
                fontSize={"2xl"}
                fontWeight={"bold"}
                color={"red.500"}
            >Your Approval is still pending</Text>
            <Button
                onClick={() => {
                    navigate('/user-registration-details/' + auth?.id)
                }}
            >
                Update Details
            </Button>
        </Flex>
    )
}

export default UnApprovedUserC;