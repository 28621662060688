import React from "react";
import {AUser} from "../../../lib/types/admin/users";
import {
    Button,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Radio, RadioGroup, Stack,
    useDisclosure
} from "@chakra-ui/react";
import {UpdateUser} from "../../../services/admin/users";
import useAxios from "../../../hooks/useAxios";
import {toast} from "react-toastify";

interface Props {
    user: AUser
}

const UpdateStatusModal:React.FC<Props> = ({user}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [value, setValue] = React.useState(user.is_approved ? 'true' : 'false');
    const [isLoading, setIsLoading] = React.useState(false);
    const axios = useAxios();
    const handleUpdate = async () => {
        try{
            setIsLoading(true);
            const data = {
                is_approved: value === 'true'
            }
            const response = await UpdateUser(axios, user.id, data);
            if(response){
                toast('User status updated successfully', {
                    type: 'success'
                })
            }else{
                toast('Something went wrong', {
                    type: 'error'
                })
            }
            setIsLoading(false);
            onClose();
        }catch (err){
            console.log(err);
        }
    }

    return (
        <>
        <Button
            colorScheme='blue'
            onClick={onOpen}
        >Update</Button>

    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
            <ModalHeader>Update User Status</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <RadioGroup
                    onChange={setValue}
                    value={value}
                >
                    <Stack spacing={5} direction='row'>
                        <Radio colorScheme='red' value={"false"}>
                            Reject
                        </Radio>
                        <Radio colorScheme='green' value={"true"}>
                            Approve
                        </Radio>
                    </Stack>
                </RadioGroup>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onClose}>
                    Close
                </Button>
                <Button
                    onClick={handleUpdate}
                    isLoading={isLoading}
                    colorScheme="green"
                >Save</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
            </>
    )
}

export default UpdateStatusModal
