import {Navigate, Route, Routes} from "react-router-dom";
import {PageNotFound} from "../pages/404";
import PersistLogin from "./PersistLogin";
import RequireAuth from "./RequireAuth";
import {Login} from "../pages/Login";
import Index from "../pages/Index";
import Unauthorized from "../pages/Unauthorized";
import {Layout} from "../components/common/Layout";
import {RegisterUser} from "../pages/RegisterUser";
import UserRegistrationDetailsPage from "../pages/UserRegistrationDetailsPage";
import Profile from "../pages/Profile";
import Users from "../pages/admin/Users";


export const Router = () => {
    return (
        <Routes>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/register-user'} element={<RegisterUser/>}/>
            <Route path={'/user-registration-details/:id'} element={<UserRegistrationDetailsPage/>}/>
            <Route
                path={'/'}
                element={<Layout/>}
            >
                <Route path={'/'} element={<Navigate to={'/home'}/>}/>
                <Route path={'/unauthorized'} element={<Unauthorized/>}/>
                <Route element={<PersistLogin/>}>
                    <Route element={<RequireAuth/>}>
                        <Route path={'/home'} element={<Index/>}/>
                        <Route path={'/profile'} element={<Profile/>}/>
                        {/* Admin Routes*/}
                        <Route path={'/admin/users'} element={<Users/>}/>
                    </Route>
                </Route>
                <Route path="*" element={<PageNotFound/>}/>
            </Route>
        </Routes>
    )
}
