import React from 'react';
import {Box, Text} from "@chakra-ui/react";
import MultipartUploader from "../components/ui/MultipartUploader";
import VideosUploadedByAUser from "../components/video/VideosUploadedByAUser";

const Index = () => {
        return (
            <Box
                display="flex"
                flexDirection="column"
                gap={8}
                mt={[
                    "10",
                    "0",
                    "0",
                    "0",
                ]}
            >
                <Text
                    fontSize="xl"
                    fontWeight="bold"
                >
                    Upload Videos here, and share the link with the concerned personal.
                    <Text
                        fontSize="xl"
                        fontWeight="bold"
                        color={"red.500"}
                    >
                        Don't share the link with anyone else.
                    </Text>
                </Text>
                <MultipartUploader/>
                <VideosUploadedByAUser/>
            </Box>
        )
    }
;

export default Index;
