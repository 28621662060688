import React, { useEffect, useState } from "react"
import {Uploader} from "../../utils/s3MultipartUploader";
import useAxios from "../../hooks/useAxios";
import {Box, Button, Input, Text} from "@chakra-ui/react";
import {toast} from "react-toastify";

export default function MultipartUploader() {
    const [file, setFile] = useState(undefined)
    const [uploader, setUploader] = useState(undefined)
    const [isUploading, setIsUploading] = useState(false)
    const [title, setTitle] = useState<string>("")
    const [progress, setProgress] = useState(0)
    const [fileURL, setFileURL] = useState(undefined)
    const axiosPrivate = useAxios();

    React.useEffect(() => {
        if (file) {
            setProgress(0)
            setFileURL(undefined)
            setIsUploading(false)
        }
    }, [file])

    const onCancel = () => {
        if (uploader) {
            // @ts-ignore
            uploader.abort()
            setIsUploading(false)
        }
    }

    const startUpload = () => {
        if (file) {
            let percentage = undefined

            const videoUploaderOptions = {
                // @ts-ignore
                fileName: file.name,
                file: file,
                api: axiosPrivate,
                title: title,
            }
            const uploader = new Uploader(videoUploaderOptions)
            //@ts-ignore
            setUploader(uploader)
            setIsUploading(true)
            uploader
                .onProgress(({ percentage: newPercentage }) => {
                    // to avoid the same percentage to be logged twice
                    if (newPercentage !== percentage) {
                        percentage = newPercentage
                        // @ts-ignore
                        setProgress(percentage)
                        setIsUploading(true)
                    }
                })
                .onFinished((file) => {
                    setFile(undefined)
                    setFileURL(file.url)
                })
                .onError((error) => {
                    setFile(undefined)
                    console.error("Uploader Component ",error)
                })

            uploader.start()
        }
    }

    //A box is a component that can be used to upload content and show percentage of completion


    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            w={300}
            p={2}
        >
            <Box>Upload your file</Box>
            <Box>
                <Input
                    type="file"
                    onChange={(e) => {
                        // @ts-ignore
                        setFile(e.target?.files?.[0])
                    }}
                />
            </Box>
            <Box>
                {file && <Input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e)=>{setTitle(e.target.value)}}
                />}
                {/* Error message if title not provided*/}
                {
                    file && !title && <Text color={"red.500"}>Title is required</Text>
                }
            </Box>
            <Box
                w={"100%"}
                display="flex"
                flexDirection="row"
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
            >
                <Box
                    w={"100%"}
                    h={2}
                    bg={"gray.200"}
                    borderRadius={4}
                >
                    <Box
                        w={`${progress}%`}
                        h={"100%"}
                        bg={"green.500"}
                        borderRadius={4}
                    />
                </Box>
                <Text>{progress}%</Text>
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent={"space-between"}
                gap={2}
            >
                <Button
                    onClick={onCancel}
                    colorScheme={"red"}
                >Cancel</Button>
                {fileURL && <Button
                    colorScheme={"blue"}
                    onClick={()=>{
                        navigator.clipboard.writeText(fileURL)
                        toast("Link copied to clipboard", {type: "success"})
                    }}
                >
                    Copy Link
                </Button>}
                <Button
                    onClick={startUpload}
                    colorScheme={"green"}
                    //Disable button if file is not selected or title is not provided
                    isDisabled={!file || !title || isUploading}
                >Upload</Button>
            </Box>
        </Box>
    )
}
