import {HStack, Icon, Link, LinkProps, useColorModeValue as mode, Text, Box} from '@chakra-ui/react'
import * as React from 'react'
import {useNavigate} from "react-router-dom";
import useNavigationStore from "../../../redux/zustand/navigation.store";

interface NavLinkProps extends LinkProps {
    isActive?: boolean
    label: string
    icon: any
    href?: string
}

export const NavLink = (props: NavLinkProps) => {
    const {icon, isActive, label} = props
    const navigate = useNavigate();
    const {currentRoute, setCurrentRoute} = useNavigationStore();
    return (
        <Box
            display="block"
            py={2}
            px={3}
            borderRadius="md"
            fontWeight="medium"
            lineHeight="1.5rem"
            aria-current={isActive ? 'page' : undefined}
            color={currentRoute === props.href ? 'black' : 'whiteAlpha.800'}
            _hover={{
                bg: mode('gray.100', 'gray.700'),
                color: mode('black', 'white'),
            }}
            bg={currentRoute === props.href ? mode('blue.500', 'blue.300') : undefined}
            onClick={() => {
                if (props?.href) {
                    navigate(props.href)
                    setCurrentRoute(props.href)
                }
            }}
        >
            <HStack spacing={4}>
                <Icon as={icon} boxSize="20px"/>
                <Text as="span">{label}</Text>
            </HStack>
        </Box>
    )
}

export const NavLinkMobile = (props: NavLinkProps) => {
    const [isCurrent, setIsCurrent] = React.useState(false)
    const {icon, isActive, label} = props
    const navigate = useNavigate();
    const {currentRoute, setCurrentRoute} = useNavigationStore();
    React.useEffect(() => {
        setIsCurrent(currentRoute === props.href)
    }, [currentRoute])
    return (
        <Link
            display="block"
            py={2}
            px={3}
            borderRadius="md"
            fontWeight="medium"
            lineHeight="1.5rem"
            color={isCurrent ? 'black' : 'whiteAlpha.800'}
            bg={isCurrent ? 'blue.500': undefined}
            onClick={() => {
                if (props?.href) {
                    navigate(props.href)
                    setCurrentRoute(props.href)
                }
            }}
        >
            <HStack spacing={4}>
                <Icon as={icon} boxSize="20px"/>
                <Text as="span">{label}</Text>
            </HStack>
        </Link>
    )
}
