import React from "react";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import {Button, Flex, LightMode, Stack} from "@chakra-ui/react";
import {InputField} from "../ui/InputField";
import SelectField from "../ui/SelectField";
import UploadImage from "../common/UploadImage";
import {UpdateUserRegistrationStep1} from "../../services/user_registration";
import {UserType} from "../../lib/types/enums";


interface UserDetailsRegistrationFormStep1Props {
    id: string;
    currentStep: number;
    handleNext: () => void;
    handleBack: () => void;

    prevUserDetails: any;
}

const UserDetailsRegistrationFormStep1: React.FC<UserDetailsRegistrationFormStep1Props> = ({
                                                                                               id,
                                                                                               currentStep,
                                                                                               handleNext,
                                                                                               handleBack,
                                                                                               prevUserDetails
                                                                                           }) => {
    const axios = useAxios();

    const validationSchema = yup.object({
        name: yup.string().required(),
        type: yup.string().required(),
        id_file_url: yup.string().required(),
        id_type: yup.string().required(),
        phone: yup.string().length(10).required(),
    });

    return (
        <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={{
                name: prevUserDetails?.name || '',
                type: prevUserDetails?.type || UserType.Creator.valueOf(),
                id_file_url: prevUserDetails?.id_file_url || '',
                id_type: prevUserDetails?.id_type || 'aadhar',
                phone: prevUserDetails?.phone || '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (data, {setSubmitting}) => {
                setSubmitting(true);
                try {
                    const response = await UpdateUserRegistrationStep1({
                        ...data,
                        phone: data.phone.toString()
                    }, axios);

                    if (response.status === 200) {
                        handleNext();
                    } else {
                        toast.error("Something went wrong");
                    }

                } catch (err: any) {
                    console.log(err);
                    const message = err.response.data.message;
                    toast.error(message);
                    return;
                }
                setSubmitting(false);
            }}
        >
            {({isSubmitting, submitForm, setValues, values, errors}) => (
                <Form>
                    <Stack spacing="8">
                        <InputField
                            name="name"
                            label="Name"
                            type="text"
                        />
                        <SelectField
                            options={[
                                {value: UserType.Creator.valueOf(), label: 'Creator'},
                                {value: UserType.Editor.valueOf(), label: 'Video Editor'},
                            ]}
                            name={'type'}
                            label="Type"
                        />
                        <InputField
                            name={'phone'}
                            label="Phone Number" type="number"
                        />

                        <SelectField
                            options={[
                                {value: 'aadhar', label: 'Aadhar'},
                                {value: 'pancard', label: 'Pan Card'},
                                {value: 'voter_id', label: 'Voter ID'},
                            ]}
                            name={'id_type'}
                            label="ID Card Type"
                        />

                        <InputField
                            name={'id_file_url'}
                            label="ID Card File URL"
                            type="text"
                            isDisabled={true}
                            isDisplayed={false}
                        />

                        <UploadImage
                            setImageUrl={(url: string) => {
                                setValues({...values, id_file_url: url})
                            }}
                            imageURL={values['id_file_url']}
                            userID={id}
                            folderName={'id_card'}
                        />
                    </Stack>
                    <Flex
                        direction={{base: 'column-reverse', md: 'row'}}
                        mt="6"
                        align="center"
                        justify="space-between"
                    >
                        <LightMode>
                            <Button
                                mb={{base: '4', md: '0'}}
                                w={{base: 'full', md: 'auto'}}
                                colorScheme="blue"
                                size="lg"
                                variant={"outline"}
                                fontSize="md"
                                fontWeight="bold"
                                isLoading={isSubmitting}
                                onClick={() => handleBack()}
                                isDisabled={currentStep === 0}
                            >
                                Go Back
                            </Button>

                            <Button
                                mb={{base: '4', md: '0'}}
                                w={{base: 'full', md: 'auto'}}
                                colorScheme="blue"
                                size="lg"
                                fontSize="md"
                                fontWeight="bold"
                                isLoading={isSubmitting}
                                onClick={() => submitForm()}
                                isDisabled={
                                    Object.keys(errors).length > 0 ||
                                    isSubmitting
                                }
                            >
                                Save
                            </Button>
                        </LightMode>
                    </Flex>

                </Form>
            )}
        </Formik>
    )
};

export default UserDetailsRegistrationFormStep1;
