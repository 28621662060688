import {Box, Text} from "@chakra-ui/react";

export const PageNotFound = () => {
    return (
        <Box>
            <Text>
                Page not found
            </Text>
        </Box>
    )
}