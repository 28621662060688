import {Button, Divider, Stack} from "@chakra-ui/react";
import {NavLink} from "../components/common/sidebar/NavLink";
import { FaUser, FaHome} from "react-icons/fa";
import {logoutUser} from "../services/auth";
import {toast} from "react-toastify";
import * as React from "react";
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {UserType} from "./types/enums";

const SidebarLinks = () => {
    const {setAuth, auth}= useAuth()
    const {role} = auth;
    const navigate = useNavigate();
    return (
        <>
            <Stack>
                <NavLink label="Home" icon={FaHome} href={'/home'} isActive/>
                {role===UserType.Admin.valueOf() && <NavLink label="Users" href={'/admin/users'} icon={FaUser}/>

                }
            </Stack>
            <Divider/>
            <Stack>
                <NavLink label="Profile" href={'/profile'} icon={FaUser}/>
            </Stack>

            <Button
                onClick={
                    async () => {
                        await logoutUser()
                        setAuth(null)
                        navigate('/login')
                        toast.success('Logged out successfully')
                    }
                }
            >Logout</Button>
        </>
    );
}

export default SidebarLinks;
