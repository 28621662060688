import React from "react";
import {GetVideosUploadedByUser} from "../../services/video";
import useAxios from "../../hooks/useAxios";
import {VideosUploadedByUserI} from "../../lib/types/video";
import {Box, Button, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {toast} from "react-toastify";

const VideosUploadedByAUser:React.FC = () => {
    const [data, setData] = React.useState<Array<VideosUploadedByUserI>|null>([]);

    const axios = useAxios();
    React.useEffect(() => {
        // Fetch videos uploaded by a user
        async function fetchVideosUploadedByAUser() {
            // Fetch videos uploaded by a user
            const response = await GetVideosUploadedByUser(axios);
            if (response) {
                setData(response.data);
            }else{
                setData(null);
            }
        }
        fetchVideosUploadedByAUser();
    }, [])

    return (
        <Box>
            <Text
                fontSize="2xl"
                fontWeight="bold"
                mb={4}
            >
                Videos Uploaded By You
            </Text>
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Title</Th>
                            <Th>Uploaded On</Th>
                            <Th>URL</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data && data.map((video, index) => (
                            <Tr key={index}>
                                <Td>{video.id}</Td>
                                <Td>{video.title}</Td>
                                <Td>{new Date(video.created_at).toDateString()}</Td>
                                <Td><Button
                                    onClick={() => {
                                        navigator.clipboard.writeText(video.url);
                                        toast("Copied to clipboard", {
                                            type: "success"
                                        })
                                    }}
                                >Copy URL</Button></Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default VideosUploadedByAUser;
