import React, {useState} from 'react';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Image,
    Input,
    VStack
} from "@chakra-ui/react";
import S3 from 'react-aws-s3';
import {s3Config} from "../../config/aws";
import Buffer from "buffer"
//@ts-ignore
window.Buffer = window.Buffer || Buffer.Buffer

interface UploadImageProps {
    setImageUrl: (url: string) => void;
    style?: React.CSSProperties;
    imageURL?: string;
    getHeightAndWidth?: (obj: any) => void;
    userID?: number | string;
    type?: string;
    folderName?: string;
}

//Upload image to firebase storage
const UploadImageComponent: React.FC<UploadImageProps> = ({
                                                              setImageUrl,
                                                              imageURL,
                                                              type,
                                                              userID,
                                                              folderName
                                                          }) => {
    const [imageAsFile, setImageAsFile] = useState('')
    const [fileName, setFileName] = useState("")
    const [isUploading, setIsUploading] = useState(false)
    const [error, setError] = useState(null)
    const [uploaded, setUploaded] = useState(false)
    const [imageHash, setImageHash] = useState<number>(Date.now());

    const handleImageAsFile = async (e) => {
        const image = e.target.files[0]
        setImageAsFile(() => (image))
        setFileName(image.name)
    }

    const fetchImageAgain = () => {
        setImageHash(Date.now())
    }

    const handleS3Upload = (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        setIsUploading(true);

        if (imageAsFile === '') {
            //@ts-ignore
            setError(`not an image, the image file is a ${typeof (imageAsFile)}`)
        } else {
            setError(null)
        }

        const extension = fileName.split('.').pop()
        const fileName_ = userID + "." + extension;

        const config_ = {
            ...s3Config,
            dirName: s3Config.dirName + "/" + folderName,
        }


        const ReactS3Client = new S3(config_);
        ReactS3Client
            .uploadFile(imageAsFile, fileName_)
            .then((data: { status: number; location: any; }) => {
                console.log("S3 success: ", data)
                if (data.status === 204) {
                    const uploadedURL = data.location;
                    setIsUploading(false)
                    setUploaded(true)
                    setImageUrl(uploadedURL)
                } else {
                    setIsUploading(false)
                    setUploaded(false)
                }
                fetchImageAgain()
            })
            .catch((err: React.SetStateAction<null>) => {
                console.log("S3 error: ", err)
                setIsUploading(false)
                setUploaded(false)
                setError(err)
                fetchImageAgain()
            })

        setIsUploading(false)
    }

    return (
        <VStack>
            <FormControl isInvalid={!!error}>
                <FormLabel>Upload Image</FormLabel>
                <HStack>
                    <Input
                        type="file"
                        name="file"
                        id="file"
                        onChange={handleImageAsFile}
                        accept={"image/*"}
                    />
                    <Button
                        isLoading={isUploading}
                        onClick={handleS3Upload}
                        colorScheme={uploaded ? "green" : "blue"}
                    >
                        Upload
                    </Button>
                </HStack>
                {uploaded && <FormHelperText color={"green"}>Uploaded</FormHelperText>}
                {error && <FormErrorMessage>{JSON.stringify(error)}</FormErrorMessage>}
            </FormControl>

            {imageURL &&
                <Image
                    style={{height: 200, border: "2px solid grey", borderRadius: 5, alignSelf: 'start'}}
                    objectFit='cover'
                    src={imageURL + "?" + imageHash}
                    alt={fileName}
                />
            }
        </VStack>
    )
}

export default UploadImageComponent;